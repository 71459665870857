<template>
  <b-btn
    variant="primary"
    size="sm"
    v-b-modal="id"
    v-b-tooltip.hover
    :title="$t('administrative-costs.material-cost-tab.buttons.update-cost')"
    id="update-cost-type-button"
    :disabled="!idMaterialCostType"
  >
    <b-icon icon="pencil" />

    <b-modal
      :id="id"
      ref="update-cost-type"
      :title="$t('administrative-costs.material-cost-tab.buttons.update-cost')"
      centered
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="resetModal"
      @show="onShow"
    >
      <b-overlay :show="loading">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :label="
              $t(
                'administrative-costs.material-cost-tab.modals.update-cost-label'
              )
            "
            label-for="name-input"
            :invalid-feedback="
              $t(
                'administrative-costs.material-cost-tab.modals.update-cost-invalid'
              )
            "
            label-cols="12"
            content-cols="12"
            :state="state.name"
          >
            <b-form-input
              id="name-input"
              :placeholder="
                $t(
                  'administrative-costs.material-cost-tab.modals.update-cost-placeholder'
                )
              "
              v-model="form.name"
              maxlength="20"
              :state="state.name"
              required
            ></b-form-input>
          </b-form-group>
        </form>
        <template #overlay>
          <simple-loader />
        </template>
      </b-overlay>
      <template v-slot:modal-footer>
        <slot name="footer">
          <b-button @click="$bvModal.hide(id)" :disabled="loading">{{
            $t("general.cancel")
          }}</b-button
          ><b-button variant="primary" @click="handleOk" :disabled="loading">{{
            $t("general.ok")
          }}</b-button></slot
        >
      </template>
    </b-modal>
  </b-btn>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      form: {
        name: null,
      },
      state: {
        name: null,
      },
      id: "update-cost-type-modal",
      loading: false,
      list: false,
    };
  },
  props: {
    idMaterialCostType: Number,
  },
  computed: {
    ...mapGetters("MaterialCosts", ["materialCostType"]),
  },
  methods: {
    ...mapActions("MaterialCosts", ["getCostType", "putCostType"]),
    checkFormValidity() {
      let valid = (this.state.name = true);
      if (!this.form.name) this.state.name = valid = false;
      return valid;
    },
    resetModal() {
      this.form = {
        name: null,
      };
      this.state = {
        name: null,
      };
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.loading = true;
      this.putCostType({
        api: this.$api,
        idMaterialCostType: this.idMaterialCostType,
        costTypeName: this.form.name,
      })
        .catch((e) => {
          this.$bvToast.toast(e, {
            variant: "danger",
            title: this.$t(
              "administrative-costs.material-cost-tab.buttons.update-cost"
            ),
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.$bvModal.hide(this.id);
          this.loading = false;
        });
    },
    onShow() {
      this.loading = true;
      this.getCostType({
        api: this.$api,
        idMaterialCostType: this.idMaterialCostType,
      })
        .then(() => {
          this.form.name = this.materialCostType.costTypeName;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
