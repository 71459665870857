<template>
  <div>
    <b-card class="h-100" no-body>
      <b-card-header header-border-variant="light">
        <b-row>
          <b-col cols="9">
            <h4>{{ $t("views.settings.title") }}</h4>
          </b-col>
          <b-col>
            <b-button-toolbar key-nav>
              <b-button-group class="ml-auto">
                <customer-settings />
              </b-button-group> </b-button-toolbar
          ></b-col>
        </b-row>
      </b-card-header>
      <b-tabs card fill>
        <b-tab :title="$t('administrative-costs.personal-tab')" active>
          <b-card-text>
            <salary-types />
          </b-card-text>
        </b-tab>
        <b-tab :title="$t('administrative-costs.material-tab')">
          <cost-types />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import CustomerSettings from "@/components/CustomerSettings.vue";
import SalaryTypes from "@/components/AdministrativeCosts/SalaryTypes.vue";
import CostTypes from "@/components/AdministrativeCosts/CostTypes.vue";

export default {
  components: {
    "customer-settings": CustomerSettings,
    "salary-types": SalaryTypes,
    "cost-types": CostTypes,
  },
};
</script>

<style></style>
