var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-btn',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(_vm.id),expression:"id"},{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"variant":"primary","size":"sm","title":_vm.$t('administrative-costs.material-cost-tab.buttons.update-cost'),"id":"update-salary-type-button","disabled":!_vm.SalaryTypeSelected}},[_c('b-icon',{attrs:{"icon":"pencil"}}),_c('b-modal',{ref:"update-salary-type",attrs:{"id":_vm.id,"title":_vm.$t('administrative-costs.material-cost-tab.buttons.update-cost'),"centered":"","header-bg-variant":"primary","header-text-variant":"light"},on:{"hidden":_vm.resetModal,"show":_vm.onShow},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_vm._t("footer",function(){return [_c('b-button',{attrs:{"disabled":_vm.busy || _vm.loading},on:{"click":function($event){return _vm.$bvModal.hide(_vm.id)}}},[_vm._v(_vm._s(_vm.$t("general.cancel")))]),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.busy || _vm.loading},on:{"click":_vm.handleOk}},[_vm._v(_vm._s(_vm.$t("general.ok")))])]})]},proxy:true}],null,true)},[_c('b-overlay',{attrs:{"show":_vm.loading},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('simple-loader')]},proxy:true}])},[_c('form',{ref:"form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":_vm.$t(
              'administrative-costs.material-cost-tab.modals.update-cost-label'
            ),"label-for":"name-input","invalid-feedback":_vm.$t(
              'administrative-costs.material-cost-tab.modals.update-cost-invalid'
            ),"label-cols":"12","content-cols":"12","state":_vm.state.name}},[_c('b-form-input',{attrs:{"id":"name-input","placeholder":_vm.$t(
                'administrative-costs.material-cost-tab.modals.update-cost-placeholder'
              ),"maxlength":"100","state":_vm.state.name,"required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('views.settings.workingHours') + ': ',"label-for":"workingHours-input","invalid-feedback":"Working hours are required and must be between 0 and 8784","label-cols":"12","content-cols":"12","state":_vm.state.workingHours}},[_c('b-form-input',{attrs:{"id":"workingHours-input","placeholder":"Enter working Hours","type":"number","state":_vm.state.workingHours,"required":"","min":"1","max":"8784"},model:{value:(_vm.form.workingHours),callback:function ($$v) {_vm.$set(_vm.form, "workingHours", $$v)},expression:"form.workingHours"}})],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }