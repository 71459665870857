<template>
  <div>
    <b-row>
      <b-col cols="12" sm="6">
        <b-form>
          <b-form-group
            :label="$t('administrative-costs.material-cost-tab.title')"
          >
            <b-form-select
              v-model="CostTypeSelected"
              :options="materialCostsTypes"
              text-field="costTypeName"
              value-field="idMaterialCostType"
              @change="onInputChange()"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>{{
                  $t(
                    "administrative-costs.material-cost-tab.cost-option-default"
                  )
                }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-form>
      </b-col>
      <b-col>
        <b-button-toolbar key-nav>
          <b-button-group class="ml-auto">
            <cost-type-creation />
            <cost-type-update :idMaterialCostType="CostTypeSelected" />
            <cost-type-delete
              :idMaterialCostType="CostTypeSelected"
              @onDelete="setInputNull"
            />
          </b-button-group>
          <b-button-group class="ml-2">
            <cost-type-download :idMaterialCostType="CostTypeSelected" />
            <cost-type-upload
              :idMaterialCostType="CostTypeSelected"
              @onUploadCSV="onInputChange()"
            /> </b-button-group></b-button-toolbar
      ></b-col>
    </b-row>
    <div v-if="CostTypeSelected">
      <b-card-body>
        <h4>{{ $t("administrative-costs.material-cost-tab.subtitle") }}</h4>
      </b-card-body>
      <b-card-body class="h-100 overflow p-0">
        <div class="table-container">
          <div v-if="loading">
            <simple-loader />
          </div>
          <div v-else>
            <div class="headers-content">
              <b-input
                plaintext
                class="inputs text-center"
                :value="$t('views.settings.administrativeCostCategories')"
              />
              <div
                v-for="classes in materialCostClasses"
                v-bind:key="classes.idMaterialCostClass"
                class="inputs"
              >
                <b-input
                  plaintext
                  class="inputs text-center"
                  :value="classes.className"
                />
              </div>
            </div>
            <div class="table-content-container">
              <b-row>
                <div
                  v-for="values in materialCostValues"
                  :key="values.idMaterialCostCategory"
                  class="table-content"
                >
                  <b-input
                    v-model="
                      materialCostCategories.find(
                        (x) =>
                          x.idMaterialCostCategory ==
                          values.idMaterialCostCategory
                      ).categoryName
                    "
                    class="inputs ml-3"
                    maxlength="250"
                    @change="
                      onChange(
                        materialCostCategories.find(
                          (x) =>
                            x.idMaterialCostCategory ==
                            values.idMaterialCostCategory
                        )
                      )
                    "
                    @click="
                      onClickInput(
                        materialCostCategories.find(
                          (x) =>
                            x.idMaterialCostCategory ==
                            values.idMaterialCostCategory
                        )
                      )
                    "
                  />
                  <currency-input
                    v-model="values.value"
                    currency="EUR"
                    min="0"
                    step="0.01"
                    max="9999999"
                    :locale="$i18n.locale"
                    :value-range="{
                      min: 0,
                      max: 999999.99,
                    }"
                    class="inputs form-control text-right pr-3"
                    @blur="onValueChange(values)"
                  />
                </div>
              </b-row>
            </div>
          </div>
          <b-button-toolbar key-nav>
            <b-button-group class="ml-auto">
              <cost-category-creation
                :idMaterialCostType="CostTypeSelected"
                @onCreateCategory="onInputChange()"
              />
              <cost-category-delete
                :idMaterialCostCategory="materialCostCategorySelected"
                @onDeleteCategory="categoryDelete()"
              />
            </b-button-group>
          </b-button-toolbar>
        </div>
      </b-card-body>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CostTypeCreation from "@/components/AdministrativeCosts/CostTypeCreation.vue";
import CostTypeDelete from "@/components/AdministrativeCosts/CostTypeDelete.vue";
import CostTypeUpdate from "@/components/AdministrativeCosts/CostTypeUpdate.vue";
import CostTypeUpload from "@/components/AdministrativeCosts/CostTypeUpload.vue";
import CostTypeDownload from "@/components/AdministrativeCosts/CostTypeDownload.vue";
import CostCategoryCreation from "@/components/AdministrativeCosts/CostCategoryCreation.vue";
import CostCategoryDelete from "@/components/AdministrativeCosts/CostCategoryDelete.vue";

export default {
  components: {
    "cost-type-creation": CostTypeCreation,
    "cost-type-delete": CostTypeDelete,
    "cost-type-update": CostTypeUpdate,
    "cost-type-upload": CostTypeUpload,
    "cost-type-download": CostTypeDownload,
    "cost-category-creation": CostCategoryCreation,
    "cost-category-delete": CostCategoryDelete,
  },
  data() {
    return {
      CostTypeSelected: null,
      loading: false,
      materialCostCategorySelected: null,
    };
  },
  computed: {
    ...mapGetters("MaterialCosts", [
      "materialCostsTypes",
      "materialCostCategories",
      "materialCostClasses",
      "materialCostValues",
    ]),
  },
  methods: {
    ...mapActions("MaterialCosts", [
      "getMaterialCostsTypes",
      "getCostCategory",
      "putCostCategory",
      "getCostClasses",
      "getCostValues",
      "putCostValue",
    ]),
    setInputNull() {
      this.CostTypeSelected = null;
    },
    onInputChange() {
      if (this.CostTypeSelected != null) {
        this.loading = true;
        this.getCostCategory({
          api: this.$api,
          idMaterialCostType: this.CostTypeSelected,
        })
          .then(() => {
            this.getCostClasses({
              api: this.$api,
              idMaterialCostType: this.CostTypeSelected,
            }).then(() => {
              this.getCostValues({
                api: this.$api,
                idMaterialCostType: this.CostTypeSelected,
              });
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    onChange(category) {
      this.putCostCategory({
        api: this.$api,
        idMaterialCostCategory: category.idMaterialCostCategory,
        categoryName: category.categoryName,
        idMaterialCostType: this.CostTypeSelected,
      }).catch((e) => {
        this.$bvToast.toast(e, {
          variant: "danger",
          title: this.$t(
            "administrative-costs.material-cost-tab.buttons.update-category"
          ),
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.onInputChange();
      });
    },
    onValueChange(values) {
      if (values.value == null) {
        this.putCostValue({
          api: this.$api,
          idMaterialCostCategory: values.idMaterialCostCategory,
          idMaterialCostClass: values.idMaterialCostClass,
          value: 0,
        });
      } else {
        this.putCostValue({
          api: this.$api,
          idMaterialCostCategory: values.idMaterialCostCategory,
          idMaterialCostClass: values.idMaterialCostClass,
          value: values.value,
        }).catch((e) => {
          this.$bvToast.toast(e, {
            variant: "danger",
            title: this.$t(
              "administrative-costs.material-cost-tab.buttons.update-value"
            ),
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.onInputChange();
        });
      }
    },
    onClickInput(category) {
      this.materialCostCategorySelected = category.idMaterialCostCategory;
    },
    categoryDelete() {
      this.materialCostCategorySelected = null;
      this.onInputChange();
    },
  },
  mounted() {
    this.loading = true;
    this.getMaterialCostsTypes({ api: this.$api })
      .then(() => {})
      .catch((e) => {
        console.log(e.response.data.text);
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style lang="scss" scoped>
input.inputs {
  height: 35px;
}

.table-container {
  text-align: left;
  background: rgba(228, 228, 228, 0.4);
  overflow-x: auto;

  .headers-content {
    display: flex;
    padding: 0px 0px 0px 0px;

    .inputs {
      width: 534px;
      height: 35px;
    }
  }

  .table-content-container {
    height: 50vh;
    width: fit-content;
    overflow-y: auto;
    overflow-x: hidden;

    .table-content {
      display: flex;
      -ms-overflow-style: none;
      padding: 0px 0px 0px 0px;

      .inputs {
        width: 538px;
      }
    }
  }
}
</style>
