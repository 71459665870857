<template>
  <div>
    <b-row>
      <b-col cols="10" sm="6">
        <b-form>
          <b-form-group :label="$t('views.settings.SalaryType')">
            <b-form-select
              v-model="SalaryTypeSelected"
              :options="SalaryTypeOptions"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>{{
                  $t(
                    "administrative-costs.material-cost-tab.cost-option-default"
                  )
                }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-form>
      </b-col>
      <b-col v-if="WorkingHours" cols="2">
        <b-form-group :label="$t('views.settings.workingHours')">
          <b-input-group class="text-right">
            {{ WorkingHours.yearlyWorkHours }}
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-button-toolbar key-nav>
          <b-button-group class="ml-auto">
            <salary-type-creation />
            <salary-type-update />
            <salary-type-delete />
          </b-button-group>
          <b-button-group class="ml-2">
            <download-excel />
            <upload-excel />
          </b-button-group>
        </b-button-toolbar>
      </b-col>
    </b-row>
    <div v-if="SalaryTypeSelected">
      <b-card-body>
        <b-alert v-model="ShowMessage" variant="success">{{ Message }}</b-alert>
        <b-row>
          <b-col>
            <h4>{{ $t("views.settings.subtitle") }}</h4>
          </b-col>
          <b-col>
            <b-button-toolbar key-nav>
              <b-button-group class="ml-auto">
                <b-dropdown size="sm" variant="primary">
                  <template #button-content>
                    <b-icon-plus />
                  </template>
                  <b-dropdown-item-button @click="addCategory">
                    {{
                      this.$t("views.settings.context.addCategory")
                    }}</b-dropdown-item-button
                  >
                  <b-dropdown-item-button @click="addSeniority">
                    {{
                      this.$t("views.settings.context.addSeniority")
                    }}</b-dropdown-item-button
                  >
                </b-dropdown>
              </b-button-group>
            </b-button-toolbar>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-body class="h-100 overflow p-0">
        <div class="grid-container">
          <div v-if="loading">
            <simple-loader />
          </div>
          <div v-else>
            <div class="header">
              <b-input
                plaintext
                class="task-name text-center"
                :value="$t('views.settings.administrativeCostCategories')"
              />
              <div
                v-for="seniority in Seniority"
                v-bind:key="seniority.idSeniority"
                class="task-name"
              >
                <b-input
                  class="task-name text-center"
                  :plaintext="seniority.average !== 0"
                  v-model="seniority.description"
                  placeholder="Name"
                  @contextmenu.prevent="
                    seniority.average !== 0
                      ? ''
                      : showSeniorityMenu($event, seniority)
                  "
                  @blur="modifySeniority(seniority)"
                />
              </div>
            </div>
            <div class="content-container">
              <div
                v-for="category in CategoriesSalaries"
                :key="category.idCategory"
                class="content"
              >
                <b-input
                  v-model="category.categoryName"
                  class="task-name"
                  placeholder="Name"
                  @contextmenu.prevent="showCategoryMenu($event, category)"
                  @blur="modifyCategory(category)"
                />
                <div
                  v-for="seniority in Seniority"
                  v-bind:key="seniority.idSeniority"
                  class="task-name"
                >
                  <currency-input
                    v-if="
                      !items ||
                      items[category.idCategory][seniority.idSeniority] != -1
                    "
                    v-model="items[category.idCategory][seniority.idSeniority]"
                    currency="EUR"
                    min="0"
                    step="0.01"
                    max="9999999"
                    :value-range="{
                      min: 0,
                      max: 9999999,
                    }"
                    :locale="$i18n.locale"
                    class="task-name form-control text-right"
                    @blur="
                      modifySalary(
                        category.idCategory,
                        seniority.idSeniority,
                        items[category.idCategory][seniority.idSeniority]
                      )
                    "
                  />
                  <div class="mx-auto" size="sm" v-else>-</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card-body>
    </div>
    <b-card-footer v-if="EditMode">
      <b-button-toolbar key-nav>
        <b-button-group class="ml-auto">
          <b-button
            v-if="EditMode"
            :title="$t('general.save')"
            variant="success"
            @click="onSave"
          >
            <font-awesome-icon icon="save" /> {{ $t("general.save") }}
          </b-button>
        </b-button-group></b-button-toolbar
      >
    </b-card-footer>
    <vue-simple-context-menu
      element-id="categoryContextMenu"
      :options="categoryContextOptions"
      ref="categoryContextMenu"
      style="position: fixed; top: 0px; left: 0px"
      @option-clicked="categoryMenuClicked"
    />
    <vue-simple-context-menu
      element-id="seniorityContextMenu"
      :options="seniorityContextOptions"
      ref="seniorityContextMenu"
      style="position: fixed; top: 0px; left: 0px"
      @option-clicked="seniorityMenuClicked"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SalaryTypeCreation from "@/components/AdministrativeCosts/SalaryTypeCreation.vue";
import SalaryTypeUpdate from "@/components/AdministrativeCosts/SalaryTypeUpdate.vue";
import SalaryTypeDelete from "@/components/AdministrativeCosts/SalaryTypeDelete.vue";
import UploadExcel from "@/components/AdministrativeCosts/UploadExcel.vue";
import DownloadExcel from "@/components/AdministrativeCosts/DownloadExcel.vue";
import router from "@/router";

export default {
  components: {
    "salary-type-creation": SalaryTypeCreation,
    "salary-type-update": SalaryTypeUpdate,
    "salary-type-delete": SalaryTypeDelete,
    "upload-excel": UploadExcel,
    "download-excel": DownloadExcel,
  },
  watch: {
    $route(newValue) {
      const { idSalaryType } = newValue.params;
      if (!idSalaryType) return;
      this.loading = true;

      this.setSalaryTypeSelected({
        session: this.$session,
        api: this.$api,
        SalaryTypeSelected: idSalaryType,
      }).then(() => {
        this.fetchCategoriesSalaries({
          session: this.$session,
          api: this.$api,
          idSalaryType: idSalaryType,
        }).then(() => {
          this.getSeniority({
            session: this.$session,
            api: this.$api,
            idSalaryType: idSalaryType,
          }).then(() => {
            this.fetchSalaries({
              session: this.$session,
              api: this.$api,
              idSalaryType: idSalaryType,
            }).then(() => {
              this.loading = false;
            });
          });
        });
      });
    },
    WorkingHours(newValue) {
      if (!newValue) return;
      this.yearlyWorkHours = newValue.yearlyWorkHours;
    },
    SalaryTypes(salaryTypes) {
      var options = [];
      salaryTypes.forEach((x) => {
        options.push({
          value: x.idSalaryType,
          text: `${x.idSalaryType} - ${x.salaryType}`,
        });
      });
      this.SalaryTypeOptions = options;
    },
    SalaryTypeSelected(newValue) {
      this.$router.push({
        name: "SalaryType",
        params: {
          idSalaryType: newValue,
        },
      });
    },
    Message(newValue) {
      this.ShowMessage = newValue.length > 0;
    },
  },
  methods: {
    ...mapActions("Salary", [
      "fetchCategoriesSalaries",
      "fetchSalaryTypes",
      "getSeniority",
      "fetchSalaries",
      "updateSalaries",
      "setSalaryTypeSelected",
      "putWorkingHours",
      "createCategory",
      "updateCategory",
      "deleteCategory",
      "updateSeniority",
      "createSeniority",
      "deleteSeniority",
      "updateSalaryValue",
      "removeSalaryType",
    ]),
    updateWorkingHour() {
      this.putWorkingHours({
        session: this.$session,
        api: this.$api,
        yearlyWorkHours: this.yearlyWorkHours,
      });
      this.yearlyWorkHours = this.WorkingHours.yearlyWorkHours;
    },
    formatter(value) {
      return new Intl.NumberFormat("de-DE").format(value);
    },
    toggleMode() {
      this.fetchSalaries({
        session: this.$session,
        api: this.$api,
        idSalaryType: this.SalaryTypeSelected,
      });
    },
    ToMasterForm() {
      router.push({
        name: "MasterForm",
      });
    },
    onSave() {
      let arr = [];

      this.CategoriesSalaries.forEach((categories) => {
        this.Seniority.forEach((seniority) => {
          const salaries = {
            idCategory: categories.idCategory,
          };
          salaries.idSeniority = seniority.idSeniority;
          const item = this.items.find(
            (i) => i.idCategory == categories.idCategory
          );
          salaries.salary = item[seniority.idSeniority];
          if (salaries.salary != -1) arr.push(salaries);
        });
      });

      this.updateSalaries({
        session: this.$session,
        api: this.$api,
        idSalaryType: this.SalaryTypeSelected,
        salaries: arr,
      });
    },
    showCategoryMenu(event, category) {
      this.$refs.seniorityContextMenu.hideContextMenu();
      this.$refs.categoryContextMenu.showMenu(event, category);
    },
    showSeniorityMenu(event, category) {
      this.$refs.categoryContextMenu.hideContextMenu();
      this.$refs.seniorityContextMenu.showMenu(event, category);
    },
    categoryMenuClicked(event) {
      this.showsContext = false;
      if (event.option.id === "delete") {
        this.removeCategory(event.item.idCategory);
      }
      if (event.option.id === "addCategory") {
        this.addCategory();
      }
    },
    seniorityMenuClicked(event) {
      this.showsContext = false;
      if (event.option.id === "delete") {
        this.removeSeniority(event.item.idSeniority);
      }
      if (event.option.id === "addSeniority") {
        this.addSeniority();
      }
    },
    addCategory() {
      this.createCategory({
        api: this.$api,
        idSalaryType: this.SalaryTypeSelected,
      }).then((e) => {
        console.log(e);
      });
    },
    modifyCategory(category) {
      this.updateCategory({
        api: this.$api,
        idSalaryType: this.SalaryTypeSelected,
        idCategory: category.idCategory,
        name: category.categoryName,
      })
        .then(() => {
          this.categoryUpdated = false;
        })
        .catch((e) => {
          this.$bvToast.toast(e.response.data.message, {
            title: this.$t("general.error"),
            autoHideDelay: 3000,
            appendToast: false,
          });
        });
    },
    modifySeniority(seniority) {
      this.updateSeniority({
        api: this.$api,
        idSalaryType: this.SalaryTypeSelected,
        idSeniority: seniority.idSeniority,
        description: seniority.description,
      }).then((e) => {
        console.log(e);
        this.$forceUpdate();
      });
    },
    modifySalary(idCategory, idSeniority, salary) {
      this.updateSalaryValue({
        api: this.$api,
        idSalaryType: this.SalaryTypeSelected,
        idSeniority: idSeniority,
        idCategory: idCategory,
        salary: salary,
      }).then((e) => {
        console.log(e);
      });
    },
    removeCategory(idCategory) {
      this.deleteCategory({
        api: this.$api,
        idSalaryType: this.SalaryTypeSelected,
        idCategory: idCategory,
      }).then((e) => {
        console.log(e);
      });
    },
    addSeniority() {
      this.createSeniority({
        api: this.$api,
        idSalaryType: this.SalaryTypeSelected,
      }).then((e) => {
        console.log(e);
      });
    },
    removeSeniority(idSeniority) {
      this.deleteSeniority({
        api: this.$api,
        idSalaryType: this.SalaryTypeSelected,
        idSeniority: idSeniority,
      }).then((e) => {
        console.log(e);
      });
    },
    groupBy(xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
  },
  computed: {
    ...mapGetters("Salary", [
      "SalaryTypes",
      "Salaries",
      "Seniority",
      "Message",
      "CategoriesSalaries",
      "WorkingHours",
    ]),
    items() {
      const arr = {};

      this.CategoriesSalaries.forEach((categories) => {
        const salaries = {
          categoryName: categories.categoryName,
          idCategory: categories.idCategory,
        };
        this.Seniority.forEach((seniority) => {
          const salary = this.Salaries.find(
            (s) =>
              s.idCategory == categories.idCategory &&
              s.idSeniority == seniority.idSeniority
          );
          salaries[seniority.idSeniority.toString()] = salary
            ? salary.salary
            : 0;
        });
        arr[categories.idCategory] = salaries;
      });

      return arr;
    },
    SalaryFields() {
      return [
        {
          key: "categoryName",
          sortable: true,
          label: this.$t("views.settings.fields.CategoryName"),
        },
        ...this.Seniority.map((s) => {
          return {
            key: s.idSeniority.toString(),
            sortable: false,
            label: s.description,
          };
        }),
      ];
    },
    categoryContextOptions() {
      return [
        {
          name: this.$t("views.settings.context.deleteCategory"),
          id: "delete",
        },
        {
          type: "divider",
        },
        {
          name: this.$t("views.settings.context.addCategory"),
          id: "addCategory",
        },
      ];
    },
    seniorityContextOptions() {
      return [
        {
          name: this.$t("views.settings.context.deleteSeniority"),
          id: "delete",
        },
        {
          type: "divider",
        },
        {
          name: this.$t("views.settings.context.addSeniority"),
          id: "addSeniority",
        },
      ];
    },
  },
  data() {
    return {
      ShowMessage: false,
      EditMode: false,
      SalaryTypeOptions: [],
      SalaryTypeSelected: null,
      SalarySelected: [],
      values: {},
      yearlyWorkHours: null,
      showsContext: false,
      categoryUpdated: false,
      loading: true,
    };
  },
  created() {
    this.fetchSalaryTypes({
      session: this.$session,
      api: this.$api,
    });

    const { idSalaryType } = this.$route.params;
    if (!idSalaryType) return;
    this.SalaryTypeSelected = idSalaryType;
    this.loading = true;
    this.setSalaryTypeSelected({
      session: this.$session,
      api: this.$api,
      SalaryTypeSelected: idSalaryType,
    }).then(() => {
      this.fetchCategoriesSalaries({
        session: this.$session,
        api: this.$api,
        idSalaryType: idSalaryType,
      }).then(() => {
        this.getSeniority({
          session: this.$session,
          api: this.$api,
          idSalaryType: idSalaryType,
        }).then(() => {
          this.fetchSalaries({
            session: this.$session,
            api: this.$api,
            idSalaryType: idSalaryType,
          }).then(() => {
            this.loading = false;
          });
        });
      });
    });
  },
  beforeRouteLeave(to, from, next) {
    this.removeSalaryType();
    next();
  },
};
</script>

<style lang="scss" scoped>
input.task-name {
  height: 35px;
}
.grid-container {
  text-align: left;
  background: rgba(228, 228, 228, 0.4);
  overflow-x: auto;
  .header {
    display: flex;
    padding: 0px 0px 0px 0px;
    div {
      padding: 0px 0px 0px 0px;
      box-sizing: border-box;
      height: 35px;
      font-weight: 600;
      color: rgb(68, 68, 68);
      border-right: 1px solid rgb(215, 215, 215);
    }
    .empty {
      min-width: 20px;
    }
    .sno {
      min-width: 50px;
      text-align: right;
    }
    .task-name {
      width: 130px;
      height: 35px;
    }
  }
  .content-container {
    height: 50vh;
    width: fit-content;
    overflow-y: auto;
    overflow-x: hidden;
    .content {
      display: flex;
      -ms-overflow-style: none;
      padding: 0px 0px 0px 0px;
      .finished {
        text-decoration: line-through;
      }
      .empty {
        min-width: 20px;
        border: none;
        background: rgba(228, 228, 228, 0.4);
      }
      .sno {
        min-width: 50px;
        text-align: right;
      }
      .task-name {
        width: 130px;
      }
    }
  }
}
</style>
