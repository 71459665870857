<template>
  <b-btn
    variant="primary"
    size="sm"
    v-b-modal="id"
    v-b-tooltip.hover
    :title="$t('administrative-costs.material-cost-tab.buttons.create-cost')"
    id="new-salary-type-button"
  >
    <b-icon icon="plus" />

    <b-modal
      :id="id"
      ref="new-salary-type"
      :title="
        $t('administrative-costs.material-cost-tab.buttons.create-cost-title')
      "
      centered
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="resetModal"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :label="
            $t(
              'administrative-costs.material-cost-tab.modals.create-cost-label'
            )
          "
          label-for="name-input"
          :invalid-feedback="
            $t(
              'administrative-costs.material-cost-tab.modals.update-cost-invalid'
            )
          "
          label-cols="12"
          content-cols="12"
          :state="state.name"
        >
          <b-form-input
            id="name-input"
            :placeholder="
              $t(
                'administrative-costs.material-cost-tab.modals.update-cost-placeholder'
              )
            "
            v-model="form.name"
            :state="state.name"
            maxlength="100"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          :label="$t('views.settings.workingHours') + ': '"
          label-for="workingHours-input"
          invalid-feedback="Working hours are required and must be between 0 and 8784"
          label-cols="12"
          content-cols="12"
          :state="state.workingHours"
        >
          <b-form-input
            id="workingHours-input"
            placeholder="Enter working Hours"
            type="number"
            v-model="form.workingHours"
            :state="state.workingHours"
            required
            min="1"
            max="8784"
          ></b-form-input>
        </b-form-group>
      </form>
      <template v-slot:modal-footer>
        <slot name="footer">
          <b-button @click="$bvModal.hide(id)" :disabled="loading">{{
            $t("general.cancel")
          }}</b-button
          ><b-button variant="primary" @click="handleOk" :disabled="loading">{{
            $t("general.ok")
          }}</b-button></slot
        >
      </template>
    </b-modal>
  </b-btn>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      busy: true,
      form: {
        name: null,
        workingHours: 1540,
      },
      state: {
        name: null,
        workingHours: null,
      },
      id: "new-salary-type-modal",
      loading: false,
      defaultWorkingHours: 1540,
    };
  },
  methods: {
    ...mapActions("Salary", ["createSalaryType"]),
    unbusyModal() {
      this.busy = false;
    },
    checkFormValidity() {
      let valid = (this.state.name = this.state.workingHours = true);
      if (!this.form.name) this.state.name = valid = false;
      if (
        !this.form.workingHours ||
        this.form.workingHours > 8784 ||
        this.form.workingHours < 0
      )
        this.state.workingHours = valid = false;
      return valid;
    },
    resetModal() {
      this.form = {
        name: null,
        workingHours: this.defaultWorkingHours,
      };
      this.state = {
        name: null,
        workingHours: null,
      };
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.loading = true;
      this.createSalaryType({
        api: this.$api,
        workingHours: this.form.workingHours,
        name: this.form.name,
      })
        .then(() => {
          this.$bvModal.hide(this.id);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
