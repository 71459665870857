<template>
  <b-button
    size="sm"
    variant="primary"
    @click="download"
    :disabled="!SalaryTypeSelected"
    v-b-tooltip.hover
    :title="$t('administrative-costs.material-cost-tab.buttons.download-csv')"
  >
    <b-icon-download />
  </b-button>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      messages: null,
    };
  },
  computed: {
    ...mapGetters("Salary", ["SalaryTypeSelected"]),
  },
  methods: {
    ...mapActions("Salary", ["downloadCSV"]),
    download() {
      this.downloadCSV({
        api: this.$api,
        idSalaryType: this.SalaryTypeSelected,
      })
        .then((response) => {
          let blob = new Blob([response.data]);
          var filename = "";
          var disposition = response.headers["content-disposition"];
          if (disposition && disposition.indexOf("attachment") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch((e) => {
          this.messages = e.response.data.messages;
        })
        .finally(() => {
          this.busy = false;
        });
    },
  },
};
</script>
