<template>
  <b-btn
    variant="primary"
    size="sm"
    v-b-modal="id"
    v-b-tooltip.hover
    :title="$t('administrative-costs.material-cost-tab.buttons.delete-cost')"
    id="update-salary-type-button"
    :disabled="!SalaryTypeSelected"
  >
    <b-icon icon="trash" />
    <confirm
      :id="id"
      :title="$t('administrative-costs.material-cost-tab.buttons.delete-cost')"
      :message="
        $t('administrative-costs.material-cost-tab.modals.delete-cost-message')
      "
      @onOk="removeSalaryType"
      variantHeader="danger"
      variantText="light"
    />
  </b-btn>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Confirm from "@/components/Confirm.vue";
export default {
  components: {
    Confirm,
  },
  data() {
    return {
      id: "delete-salary-type-modal",
    };
  },
  computed: {
    ...mapGetters("Salary", ["SalaryTypeSelected"]),
  },
  methods: {
    ...mapActions("Salary", ["deleteSalaryType"]),
    onShow() {
      this.idSalaryType = this.SalaryTypeSelected;
    },
    removeSalaryType() {
      this.deleteSalaryType({
        api: this.$api,
        idSalaryType: this.SalaryTypeSelected,
      }).then(() => {
        this.$router.go();
      });
    },
  },
};
</script>

<style></style>
