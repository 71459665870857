<template>
  <b-btn
    variant="primary"
    size="sm"
    v-b-tooltip.hover
    v-b-modal="id"
    :title="$t('administrative-costs.material-cost-tab.buttons.upload-csv')"
    id="upload-excel-cost-button"
    :disabled="!idMaterialCostType"
  >
    <b-icon-upload />
    <b-modal
      :id="id"
      ref="upload-excel-cost"
      :title="$t('administrative-costs.material-cost-tab.buttons.upload-csv')"
      centered
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <b-overlay :show="busy">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :label="
              $t(
                'administrative-costs.material-cost-tab.modals.upload-csv-label'
              )
            "
            label-for="file-input"
            :invalid-feedback="
              $t(
                'administrative-costs.material-cost-tab.modals.upload-csv-invalid'
              )
            "
            label-cols="12"
            content-cols="12"
            :state="state.jsonFile"
          >
            <b-form-file
              v-model="excelFile"
              :state="state.jsonFile"
              accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
            ></b-form-file>
            <xlsx-read :file="excelFile" :options="{ raw: true }">
              <xlsx-json @parsed="form.jsonFile = $event" />
            </xlsx-read>
          </b-form-group>
        </form>
        <template #overlay>
          <simple-loader />
        </template>
      </b-overlay>
      <template v-slot:modal-footer>
        <slot name="footer">
          <b-button @click="$bvModal.hide(id)" :disabled="busy">{{
            $t("general.cancel")
          }}</b-button
          ><b-button variant="primary" @click="handleOk" :disabled="busy">{{
            $t("general.ok")
          }}</b-button></slot
        >
      </template>
    </b-modal>
    <b-modal
      :id="idErrorModal"
      :title="
        $t(
          'administrative-costs.material-cost-tab.buttons.upload-csv-error-title'
        )
      "
      centered
      size="lg"
      okOnly
      scrollable
      header-bg-variant="danger"
      header-text-variant="light"
    >
      <b-table :items="errorsArray" />
    </b-modal>
  </b-btn>
</template>

<script>
import { mapActions } from "vuex";
import { XlsxRead, XlsxJson } from "vue-xlsx";

export default {
  components: {
    XlsxRead,
    XlsxJson,
  },
  data() {
    return {
      busy: false,
      form: {
        jsonFile: null,
      },
      state: {
        jsonFile: null,
      },
      id: "upload-cost-excel-modal",
      idErrorModal: "upload-cost-error-modal-list",
      errorsArray: [],
      loading: false,
      excelFile: null,
    };
  },
  props: {
    idMaterialCostType: Number,
  },
  methods: {
    ...mapActions("MaterialCosts", ["putUploadCostTypeCSV"]),
    checkFormValidity() {
      let valid = (this.state.jsonFile = true);
      if (!this.form.jsonFile) this.state.jsonFile = valid = false;
      return valid;
    },
    resetModal() {
      this.form = {
        jsonFile: null,
      };
      this.state = {
        jsonFile: null,
      };
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.busy = true;
      this.putUploadCostTypeCSV({
        api: this.$api,
        idMaterialCostType: this.idMaterialCostType,
        jsonFile: this.form.jsonFile,
      })
        .then((response) => {
          this.$emit("onUploadCSV");
          this.$bvToast.toast(response.data.text, {
            variant: "success",
            title: this.$t(
              "administrative-costs.material-cost-tab.buttons.upload-csv"
            ),
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((e) => {
          if (e.messages) {
            this.errorsArray = e.messages;
            this.$bvModal.show(this.idErrorModal);
          } else {
            this.$bvToast.toast(e.text, {
              variant: "danger",
              title: this.$t(
                "administrative-costs.material-cost-tab.buttons.upload-csv"
              ),
              autoHideDelay: 5000,
              appendToast: true,
            });
          }
        })
        .finally(() => {
          this.busy = false;
          this.$bvModal.hide(this.id);
          this.excelFile = null;
          this.form = {
            jsonFile: null,
          };
          this.state = {
            jsonFile: null,
          };
        });
    },
  },
};
</script>
