<template>
  <b-btn
    variant="primary"
    size="sm"
    v-b-modal.modal-customer-settings
    v-b-tooltip.hover
    :title="$t('general.customerSettings')"
  >
    <b-icon icon="gear" />

    <b-modal
      :id="id"
      ref="modal"
      :title="$t('views.settings.customer.title')"
      centered
      header-bg-variant="primary"
      header-text-variant="light"
      :busy="busy"
      @show="onShow"
      @hidden="resetModal"
    >
      <b-overlay :show="busy">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :label="$t('views.settings.customer.months') + ': '"
            label-for="months-input"
            invalid-feedback="Months is required"
            label-cols="12"
            content-cols="12"
            :state="state.months"
          >
            <b-form-input
              id="months-input"
              placeholder="Enter Months"
              type="number"
              v-model="form.months"
              :state="state.months"
              :disabled="busy"
              min="0"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            :label="$t('views.settings.customer.surcharge') + ': '"
            label-for="surcharge-input"
            invalid-feedback="Surcharge is required"
            label-cols="12"
            content-cols="12"
            :state="state.surcharge"
          >
            <b-form-input
              id="surcharge-input"
              placeholder="Enter surcharge"
              type="number"
              v-model="form.surcharge"
              :state="state.surcharge"
              :disabled="busy"
              required
              min="0"
            ></b-form-input>
          </b-form-group>
        </form>
        <template #overlay>
          <simple-loader />
        </template>
      </b-overlay>
      <template v-slot:modal-footer>
        <slot name="footer">
          <b-button @click="$bvModal.hide(id)" :disabled="busy">{{
            $t("general.cancel")
          }}</b-button
          ><b-button variant="primary" @click="handleOk" :disabled="busy">{{
            $t("general.ok")
          }}</b-button></slot
        >
      </template>
    </b-modal>
  </b-btn>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      busy: true,
      form: {
        months: null,
        surcharge: null,
      },
      state: {
        months: null,
        surcharge: null,
      },
      id: "modal-customer-settings",
    };
  },
  computed: {
    ...mapGetters("Customer", ["customerSettings"]),
  },
  methods: {
    ...mapActions("Customer", ["getCustomerSettings", "putCustomerSettings"]),
    busyModal() {
      this.busy = true;
    },
    unbusyModal() {
      this.busy = false;
    },
    checkFormValidity() {
      let valid = (this.state.months = this.state.surcharge = true);
      if (!this.form.months) this.state.months = valid = false;
      if (!this.form.surcharge && this.form.surcharge != 0)
        this.state.surcharge = valid = false;
      return valid;
    },
    onShow() {
      this.busyModal();
      this.getCustomerSettings({
        session: this.$session,
        api: this.$api,
      })
        .then(() => {
          this.form = { ...this.customerSettings };
        })
        .finally(this.unbusyModal);
    },
    resetModal() {
      this.form = {
        months: null,
        surcharge: null,
      };
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.busyModal();
      this.putCustomerSettings({
        session: this.$session,
        api: this.$api,
        ...this.form,
      }).finally(() => {
        this.unbusyModal();
        this.$nextTick(() => {
          this.$bvModal.hide("modal-customer-settings");
        });
      });
    },
  },
};
</script>

<style></style>
