<template>
  <b-btn
    variant="danger"
    size="sm"
    v-b-modal="id"
    v-b-tooltip.hover
    :title="
      $t('administrative-costs.material-cost-tab.buttons.delete-category')
    "
    id="update-cost-category-button"
    :disabled="!idMaterialCostCategory"
  >
    <b-icon icon="trash" />
    <confirm
      :id="id"
      :title="
        $t('administrative-costs.material-cost-tab.buttons.delete-category')
      "
      :message="
        $t(
          'administrative-costs.material-cost-tab.modals.delete-category-message'
        )
      "
      @onOk="removeCostType()"
      variantHeader="danger"
      variantText="light"
    />
  </b-btn>
</template>

<script>
import { mapActions } from "vuex";
import Confirm from "@/components/Confirm.vue";

export default {
  components: {
    Confirm,
  },
  props: {
    idMaterialCostCategory: Number,
    idMaterialCostClass: Number,
  },
  data() {
    return {
      id: "delete-cost-category-modal",
    };
  },
  methods: {
    ...mapActions("MaterialCosts", ["deleteCostCategory"]),
    removeCostType() {
      this.deleteCostCategory({
        api: this.$api,
        idMaterialCostCategory: this.idMaterialCostCategory,
      })
        .then((response) => {
          this.$emit("onDeleteCategory");
          this.$bvToast.toast(response.data.text, {
            variant: "success",
            title: this.$t(
              "administrative-costs.material-cost-tab.buttons.delete-category"
            ),
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.$bvModal.hide(this.id);
        });
    },
  },
};
</script>
