var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-btn',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(_vm.id),expression:"id"},{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"variant":"primary","size":"sm","title":_vm.$t('administrative-costs.material-cost-tab.buttons.create-cost'),"id":"new-cost-type-button"}},[_c('b-icon',{attrs:{"icon":"plus"}}),_c('b-modal',{ref:"new-cost-type",attrs:{"id":_vm.id,"title":_vm.$t('administrative-costs.material-cost-tab.buttons.create-cost-title'),"centered":"","header-bg-variant":"primary","header-text-variant":"light"},on:{"hidden":_vm.resetModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_vm._t("footer",function(){return [_c('b-button',{attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.$bvModal.hide(_vm.id)}}},[_vm._v(_vm._s(_vm.$t("general.cancel")))]),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.loading},on:{"click":_vm.handleOk}},[_vm._v(_vm._s(_vm.$t("general.ok")))])]})]},proxy:true}],null,true)},[_c('form',{ref:"form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":_vm.$t(
            'administrative-costs.material-cost-tab.modals.create-cost-label'
          ),"label-for":"name-input","invalid-feedback":_vm.$t(
            'administrative-costs.material-cost-tab.modals.create-cost-invalid'
          ),"label-cols":"12","content-cols":"12","state":_vm.state.name}},[_c('b-form-input',{attrs:{"id":"name-input","placeholder":_vm.$t(
              'administrative-costs.material-cost-tab.modals.create-cost-placeholder'
            ),"state":_vm.state.name,"maxlength":"20","required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }