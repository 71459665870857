<template>
  <b-button
    size="sm"
    variant="primary"
    v-b-tooltip.hover
    @click="downloadCSV"
    :title="$t('administrative-costs.material-cost-tab.buttons.download-csv')"
    :disabled="!idMaterialCostType"
  >
    <b-icon-download />
  </b-button>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    idMaterialCostType: Number,
  },
  methods: {
    ...mapActions("MaterialCosts", ["getDownloadCostTypeCSV"]),
    downloadCSV() {
      this.getDownloadCostTypeCSV({
        api: this.$api,
        idMaterialCostType: this.idMaterialCostType,
      })
        .then((response) => {
          let blob = new Blob([response.data]);
          var filename = "";
          var disposition = response.headers["content-disposition"];
          if (disposition && disposition.indexOf("attachment") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch((e) => {
          this.$bvToast.toast(e, {
            variant: "danger",
            title: this.$t(
              "administrative-costs.material-cost-tab.buttons.download-csv"
            ),
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },
  },
};
</script>
