<template>
  <b-btn
    variant="success"
    size="sm"
    v-b-modal="id"
    v-b-tooltip.hover
    :title="
      $t('administrative-costs.material-cost-tab.buttons.create-category')
    "
    id="new-cost-category-button"
  >
    <b-icon icon="plus" />

    <b-modal
      :id="id"
      ref="new-cost-category"
      :title="
        $t('administrative-costs.material-cost-tab.buttons.create-category')
      "
      centered
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="resetModal"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :label="
            $t(
              'administrative-costs.material-cost-tab.modals.create-category-label'
            )
          "
          label-for="name-input"
          :invalid-feedback="
            $t(
              'administrative-costs.material-cost-tab.modals.create-category-invalid'
            )
          "
          label-cols="12"
          content-cols="12"
          :state="state.name"
        >
          <b-form-input
            id="name-input"
            :placeholder="
              $t(
                'administrative-costs.material-cost-tab.modals.create-category-placeholder'
              )
            "
            v-model="form.name"
            :state="state.name"
            maxlength="20"
            required
          ></b-form-input>
        </b-form-group>
      </form>
      <template v-slot:modal-footer>
        <slot name="footer">
          <b-button @click="$bvModal.hide(id)" :disabled="loading">{{
            $t("general.cancel")
          }}</b-button
          ><b-button variant="primary" @click="handleOk" :disabled="loading">{{
            $t("general.ok")
          }}</b-button></slot
        >
      </template>
    </b-modal>
  </b-btn>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      form: {
        name: null,
      },
      state: {
        name: null,
      },
      id: "new-cost-category-modal",
      loading: false,
    };
  },
  props: {
    idMaterialCostType: Number,
  },
  methods: {
    ...mapActions("MaterialCosts", ["postCostCategory"]),
    checkFormValidity() {
      let valid = (this.state.name = true);
      if (!this.form.name) this.state.name = valid = false;
      return valid;
    },
    resetModal() {
      this.form = {
        name: null,
      };
      this.state = {
        name: null,
      };
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.loading = true;
      this.postCostCategory({
        api: this.$api,
        categoryName: this.form.name,
        idMaterialCostType: this.idMaterialCostType,
      })
        .then((response) => {
          this.$emit("onCreateCategory");
          this.$bvToast.toast(response.data.information.text, {
            variant: "success",
            title: this.$t(
              "administrative-costs.material-cost-tab.buttons.create-category"
            ),
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((e) => {
          this.$bvToast.toast(e, {
            variant: "danger",
            title: this.$t(
              "administrative-costs.material-cost-tab.buttons.create-category"
            ),
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.$bvModal.hide(this.id);
          this.loading = false;
        });
    },
  },
};
</script>
