<template>
  <b-btn
    variant="primary"
    size="sm"
    v-b-tooltip.hover
    v-b-modal="id"
    :title="$t('administrative-costs.material-cost-tab.buttons.upload-csv')"
    id="upload-excel-salary-button"
    :disabled="!SalaryTypeSelected"
  >
    <b-icon-upload />
    <b-modal
      :id="id"
      ref="upload-excel-salary"
      :title="$t('administrative-costs.material-cost-tab.buttons.upload-csv')"
      centered
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="resetModal"
      @show="onShow"
    >
      <b-overlay :show="busy">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :label="
              $t(
                'administrative-costs.material-cost-tab.modals.upload-csv-label'
              )
            "
            label-for="file-input"
            :invalid-feedback="
              $t(
                'administrative-costs.material-cost-tab.modals.upload-csv-invalid'
              )
            "
            label-cols="12"
            content-cols="12"
            :state="state.jsonFile"
          >
            <b-form-file
              v-model="excelFile"
              :state="state.jsonFile"
              accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
            ></b-form-file>
            <xlsx-read :file="excelFile" :options="{ raw: true }">
              <xlsx-json @parsed="form.jsonFile = $event" />
            </xlsx-read>
          </b-form-group>
        </form>
        <b-alert
          show
          v-for="(message, index) in messages"
          v-bind:key="index"
          variant="danger"
          >{{ message.description }}</b-alert
        >
        <template #overlay>
          <simple-loader />
        </template>
      </b-overlay>
      <template v-slot:modal-footer>
        <slot name="footer">
          <b-button @click="$bvModal.hide(id)" :disabled="busy">{{
            $t("general.cancel")
          }}</b-button
          ><b-button variant="primary" @click="handleOk" :disabled="busy">{{
            $t("general.ok")
          }}</b-button></slot
        >
      </template>
    </b-modal>
  </b-btn>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { XlsxRead, XlsxJson } from "vue-xlsx";

export default {
  components: {
    XlsxRead,
    XlsxJson,
  },
  data() {
    return {
      busy: false,
      form: {
        jsonFile: null,
      },
      state: {
        jsonFile: null,
      },
      id: "upload-excel-modal",
      idSalaryType: null,
      excelFile: null,
      messages: [],
    };
  },
  computed: {
    ...mapGetters("Salary", ["SalaryTypeSelected"]),
  },
  methods: {
    ...mapActions("Salary", ["uploadCSV"]),
    checkFormValidity() {
      let valid = (this.state.jsonFile = true);
      if (!this.form.jsonFile) this.state.jsonFile = valid = false;
      return valid;
    },
    resetModal() {
      this.form = {
        jsonFile: null,
      };
      this.state = {
        jsonFile: null,
      };
      this.messages = null;
    },
    handleOk(modalEvent) {
      modalEvent.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.busy = true;
      console.log(this.form.jsonFile);
      this.uploadCSV({
        api: this.$api,
        idSalaryType: this.idSalaryType,
        jsonFile: this.form.jsonFile,
      })
        .then(() => {
          this.$bvModal.hide(this.id);
          window.location.reload();
        })
        .catch((e) => {
          this.messages = e.response.data.messages;
        })
        .finally(() => {
          this.busy = false;
        });
    },
    onShow() {
      this.idSalaryType = this.SalaryTypeSelected;
      this.messages = [];
    },
  },
};
</script>
