<template>
  <b-btn
    variant="primary"
    size="sm"
    v-b-modal="id"
    v-b-tooltip.hover
    :title="$t('administrative-costs.material-cost-tab.buttons.delete-cost')"
    id="update-cost-type-button"
    :disabled="!idMaterialCostType"
  >
    <b-icon icon="trash" />
    <confirm
      :id="id"
      :title="$t('administrative-costs.material-cost-tab.buttons.delete-cost')"
      :message="
        $t('administrative-costs.material-cost-tab.modals.delete-cost-message')
      "
      @onOk="removeCostType()"
      variantHeader="danger"
      variantText="light"
    />
  </b-btn>
</template>

<script>
import { mapActions } from "vuex";
import Confirm from "@/components/Confirm.vue";

export default {
  components: {
    Confirm,
  },
  props: {
    idMaterialCostType: Number,
  },
  data() {
    return {
      id: "delete-cost-type-modal",
    };
  },
  methods: {
    ...mapActions("MaterialCosts", ["deleteCostType"]),
    removeCostType() {
      this.deleteCostType({
        api: this.$api,
        idMaterialCostType: this.idMaterialCostType,
      })
        .then((response) => {
          this.$emit("onDelete");
          this.$bvToast.toast(response.data.text, {
            variant: "success",
            title: this.$t(
              "administrative-costs.material-cost-tab.buttons.delete-cost"
            ),
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.$bvModal.hide(this.id);
        });
    },
  },
};
</script>
